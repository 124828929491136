import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { Row, Panel, Table, Toggle, Select, Button } from "@telosalliance/ui-core";
import { confirm as Confirm } from "@telosalliance/ui-core-framework"
import { RequestAPI, RequestMethods, RequestPrefix, Breadcrumb, Warnings, LoadIndicator, InfoTooltip, _mergeArray } from '../Utils';
import { clone as _clone, merge as _merge } from 'lodash';

const TonesPage = ({ sitePadding, pageType, helpContext, warnings }) => {
  let refs = {};
  let apiUrl = '/config/tones';
  let pageTitle = 'Tone Configuration';
  let pageNote = 'Note: Uploaded tones must be in AU file format, 48kHz linear PCM.';
  let pageTones = {
    cp_zones: [],
    cp_tones: [],
    cd_tones: []
  };
  let pagePath = null;
  
  switch (pageType) {
    case 'dtmf':
      apiUrl += '/dtmf';
      pageTitle = 'On-Air DTMF Configuration';
      pageTones = [];
      pagePath = [{link: '/tones', text: 'Tones'}];
      break;
    case 'ringtone':
      apiUrl += '/ringer';
      pageTitle = 'Ringtone Configuration';
      pageNote = 'Note: Uploaded tones must be in AU file format, 8kHz linear PCM.';
      pageTones = [];
      pagePath = [{link: '/tones', text: 'Tones'}];
      break;
    default:
      break;
  }

  const Warning = { variant: 'warning' }; //Alert, Confirm

  const [loading, setLoading] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [tones, setTones] = useState(pageTones);

  const cpTones = {
    'cp_dialtone': 'Dial Tone',
    'cp_ringback': 'Ringback Tone',
    'cp_busy': 'Busy Tone',
    'cp_reorder': 'Reorder Tone',
    'cp_error': 'Error Tone',
  };
  const cdTones = {
    'fx_answer': 'Call Answered',
    'fx_hangup': 'Caller Hang Up',
    'fx_switch': 'Line Switch',
    'fx_alert': 'Caller Alert*',
  };
  const dtmfTones = {
    'dtmf_1': '1',
    'dtmf_2': '2',
    'dtmf_3': '3',
    'dtmf_4': '4',
    'dtmf_5': '5',
    'dtmf_6': '6',
    'dtmf_7': '7',
    'dtmf_8': '8',
    'dtmf_9': '9',
    'dtmf_0': '0',
    'dtmf_star': '*',
    'dtmf_pound': '#',
    'dtmf_a': 'A',
    'dtmf_b': 'B',
    'dtmf_c': 'C',
    'dtmf_d': 'D',
  };
  const ringTones = {
    'ringtone_0': 'Default',
    'ringtone_1': '1',
    'ringtone_2': '2',
    'ringtone_3': '3',
    'ringtone_4': '4',
    'ringtone_5': '5',
    'ringtone_6': '6',
    'ringtone_7': '7',
    'ringtone_8': '8',
    'ringtone_9': '9',
  };

  function refreshTones() {
    RequestAPI(apiUrl, null, null, data => {
      setLoading(false);
      if (data.cp_tones) data.cp_tones.map(tone => tone.reset = false);
      if (data.cd_tones) data.cd_tones.map(tone => tone.reset = false);
      setTones(data);
    });
  }

  function updateTone(type, index, value) {
    let t = _clone(type ? tones[type] : tones);
    t[index] = _merge(t[index], value);
    if (type) setTones(_mergeArray({}, tones, {[type]: t}));
    else setTones(t);
    setUnsaved(true);
  }
  function resetTone(type, index) {
    updateTone(type, index, { reset: true });
    saveTones();
  }
  function updateTones(value) {
    setTones(_merge({}, tones, value));
    setUnsaved(true);
  }
  function saveTones(e = null) {
    if (e) e.preventDefault();

    setUnsaved(false);

    setLoading(true);
    RequestAPI(apiUrl, tones, RequestMethods.POST, data => {
      refreshTones();
    });
  }

  const uploadTone = type => ev => {
    saveTones();

    const fileInput = ev.currentTarget;
    //const filename = fileInput.value.replace(/.*[/\\]/, '');
    const formData = new FormData(fileInput.form);

    setLoading(true);
    RequestAPI('/upload/Tones/' + type, formData, RequestMethods.POST, (result) => {
      fileInput.value = '';
      refreshTones();
    });
  }

  function createDownload(content, filename, type) {
    const a = document.createElement("a");
    const file = new Blob([content], { type });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
  };

  function downloadFile(e, path) {
    e.preventDefault();

    setLoading(true);
    RequestAPI(path, null, null, (blob, filename) => {
      if (blob && filename) createDownload(blob, filename, blob.type);
      setLoading(false);
    }, null, { requestBlob: true });
  }

  useEffect(() => {
    setLoading(true);
    refreshTones();
  }, []);

  return (
    <>
      <LoadIndicator open={loading}/>
      <Breadcrumb item={pageTitle} path={pagePath}/>
      <Warnings value={warnings}/>

      <h1>{pageTitle}</h1>
      <h4>{pageNote}</h4>
      <br/>

      <Prompt when={unsaved} message="Tones have been changed but haven't been saved. Continue?"/>
      { pageType ?
        <Row spacing={sitePadding}>        
          <Panel title="Settings">
            <form onSubmit={saveTones}>
                <Table
                  columnWidths={["125px", "", "1px", "1px", "1px"]}
                  headers={[
                    <>Type<InfoTooltip source={helpContext} path={"tones/" + pageType + "-type"}/></>,
                    <>File<InfoTooltip source={helpContext} path={"tones/" + pageType + "-file"}/></>,
                    <>Enabled<InfoTooltip source={helpContext} path={"tones/" + pageType + "-enabled"}/></>,
                    "", ""]}
                  rows={tones.map((tone, index) => [
                    pageType === 'dtmf' ? dtmfTones[tone.name] : ringTones[tone.name],
                    <>
                      { tone.filename === null ?
                        pageType !== "ringtone" ? "Default" : index === 0 ? "Default" : "None" :
                        <><a href={"#" + tone.filename} onClick={(e) => downloadFile(e, "/download/Tones/custom/" + tone.filename)}>{tone.filename}</a>, <span dangerouslySetInnerHTML={{__html: tone.date}}/>{tone.size ? ', ' + (Number(tone.size) / 1024).toFixed(2) + ' KB' : ''}</> 
                      }
                    </>,
                    <Toggle disabled={pageType === "ringtone" && !tone.filename && index !== 0} checked={tone.enabled} onChange={(value) => { updateTone(null, index, {enabled: value}); }} />,
                    <>
                      <form onSubmit={ev => ev.preventDefault()}>
                        <input ref={input => refs[tone.name] = input} type="file" name="uploadControl" className="uic-inputFile" onChange={uploadTone(tone.name)}/>
                      </form>
                      <Button onClick={() => refs[tone.name].click()}>Upload</Button>
                    </>,
                    <Button disabled={!tone.filename} onClick={async () => { if (await Confirm("This will reset the tone. Continue?", Warning)) resetTone(null, index); }}>Reset</Button>,
                  ])}/>
                <div className="btn-row">
                  <Button color="blue" type="submit">Save</Button>
                </div>
              </form>
          </Panel>
        </Row>
      :
        <Row spacing={sitePadding}>        
          <Panel title="Call Progress">
            <form onSubmit={saveTones}>
              <Table
                columnWidths={["130px", ""]}
                rows={[
                  ["Default Tones", <><Select value={tones.cp_zone} onChange={(value) => { updateTones({cp_zone: value}); }}>{tones.cp_zones.map((id) => <option value={id}>{id}</option>)}</Select><InfoTooltip source={helpContext} path="tones/default"/></>],
                  [null,
                    <Table
                      columnWidths={["125px", "", "1px", "1px", "1px"]}
                      headers={[
                        <>Type<InfoTooltip source={helpContext} path="tones/cp-type"/></>,
                        <>File<InfoTooltip source={helpContext} path="tones/cp-file"/></>,
                        <>Enabled<InfoTooltip source={helpContext} path="tones/cp-enabled"/></>,
                        "", ""]}
                      rows={tones.cp_tones.map((tone, index) => [
                        cpTones[tone.name],
                        <>
                          { tone.filename === null ?
                            "Default" :
                            <><a href={"#" + tone.filename} onClick={(e) => downloadFile(e, "/download/Tones/custom/" + tone.filename)}>{tone.filename}</a>, <span dangerouslySetInnerHTML={{__html: tone.date}}/>{tone.size ? ', ' + (Number(tone.size) / 1024).toFixed(2) + ' KB' : ''}</> 
                          }
                        </>,
                        <Toggle checked={tone.enabled} onChange={(value) => { updateTone('cp_tones', index, {enabled: value}); }} />,
                        <>
                          <form onSubmit={ev => ev.preventDefault()}>
                            <input ref={input => refs[tone.name] = input} type="file" name="uploadControl" className="uic-inputFile" onChange={uploadTone(tone.name)}/>
                          </form>
                          <Button onClick={() => refs[tone.name].click()}>Upload</Button>
                        </>,
                        <Button disabled={!tone.filename} onClick={async () => { if (await Confirm("This will reset the tone. Continue?", Warning)) resetTone('cp_tones', index); }}>Reset</Button>,
                      ])}/>
                  ]
                ]}/>
                <div className="btn-row">
                  <Button color="blue" type="submit">Save</Button>
                </div>
              </form>
          </Panel>

          <Panel title="Call Disposition">
            <form onSubmit={saveTones}>
              <Table
                columnWidths={["130px", ""]}
                rows={[
                  [null,
                    <Table
                      columnWidths={["125px", "", "1px", "1px", "1px"]}
                      headers={[
                        <>Type<InfoTooltip source={helpContext} path="tones/cd-type"/></>,
                        <>File<InfoTooltip source={helpContext} path="tones/cd-file"/></>,
                        <>Enabled<InfoTooltip source={helpContext} path="tones/cd-enabled"/></>,
                        "", ""]}
                      rows={tones.cd_tones.map((tone, index) => [
                        cdTones[tone.name],
                        <>
                          { tone.filename === null ?
                            "Default" :
                            <><a href={"#" + tone.filename} onClick={(e) => downloadFile(e, "/download/Tones/custom/" + tone.filename)}>{tone.filename}</a>, <span dangerouslySetInnerHTML={{__html: tone.date}}/></> 
                          }
                        </>,
                        <Toggle checked={tone.enabled} onChange={(value) => { updateTone('cd_tones', index, {enabled: value}); }} />,
                        <>
                          <form onSubmit={ev => ev.preventDefault()}>
                            <input ref={input => refs[tone.name] = input} type="file" name="uploadControl" className="uic-inputFile" onChange={uploadTone(tone.name)}/>
                          </form>
                          <Button onClick={() => refs[tone.name].click()}>Upload</Button>
                        </>,
                        <Button disabled={!tone.filename} onClick={async () => { if (await Confirm("This will reset the tone. Continue?", Warning)) resetTone('cd_tones', index); }}>Reset</Button>,
                      ])}/>
                  ]
                ]}/>
                <div className="btn-row">
                  <Button color="blue" type="submit">Save</Button>
                </div>
              </form>
          </Panel>
        </Row>
      }
    </>
  );
}

export { TonesPage };